@import "./variables.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";

.scrollable-pane {
    max-height: 520px;
    overflow-y: scroll;
}

 .container-left {
     margin-left: 0! important;
     margin-right: 0! important;
 }